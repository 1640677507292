<template>
  <q-page padding>
    <h5>Autor</h5>
    <q-form
      @submit.prevent="handleSubmit"
      class=" q-gutter-md"
    >
      <q-input
        label="Foto"
        v-model="img"
        type="file"
        stack-label
      />
      <q-input
        label="Nome"
        v-model="form.name"
        type="text"
        :rules="[ val => val && val.length > 0 || 'Favor informe um nome valido!']"
      />
      <q-input
        label="Descrição"
        v-model="form.description"
        type="text"
        :rules="[ val => val && val.length > 0 || 'Favor informe uma descrição valida!']"
      />
      <q-editor
        placeholder="Biografia"
        v-model="form.bio"
        min-height="10rem"
        :toolbar="[
        [
          {
            label: $q.lang.editor.align,
            icon: $q.iconSet.editor.align,
            fixedLabel: true,
            list: 'only-icons',
            options: ['left', 'center', 'right', 'justify']
          },
          {
            label: $q.lang.editor.align,
            icon: $q.iconSet.editor.align,
            fixedLabel: true,
            options: ['left', 'center', 'right', 'justify']
          }
        ],
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['print', 'fullscreen'],
        [
          {
            label: $q.lang.editor.formatting,
            icon: $q.iconSet.editor.formatting,
            list: 'no-icons',
            options: [
              'p',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'code'
            ]
          },
          {
            label: $q.lang.editor.fontSize,
            icon: $q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'size-1',
              'size-2',
              'size-3',
              'size-4',
              'size-5',
              'size-6',
              'size-7'
            ]
          },
          'removeFormat'
        ],
        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

        ['undo', 'redo'],
        ['viewsource']
      ]"
        :fonts="{}"
      />
      <q-card flat bordered>
        <q-card-section>
          <pre style="white-space: pre-line">{{ form.bio }}</pre>
        </q-card-section>
      </q-card>
      <q-card flat bordered>
        <q-card-section v-html="form.bio" />
      </q-card>
      <q-btn
        :label=" isUpdated? 'Atualizar': 'Salvar'"
        color="positive"
        type="submit"
      />
      <q-btn
        label="Cancelar"
        color="warning"
        :to="{name:'author'}"
      />

    </q-form>
  </q-page>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import useApi from '../../composables/UseApi'
import useNotify from '../../composables/UseNotify'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import DOMPurify from 'dompurify'

export default defineComponent({
  name: 'PageFormAutor',

  setup () {
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const table = 'Autor'
    const { post, getById, update, uploadImg } = useApi()
    const { notifyError, notifySuccess } = useNotify()
    const isUpdated = computed(() => route.params.id)
    onMounted(async () => {
      if (isUpdated.value) {
        await handleGetAuthor(isUpdated.value)
      }
    })

    let author = {}

    const form = ref({
      name: '',
      description: '',
      bio: '',
      img_url: null
    })
    const img = ref([])

    const handleSubmit = async () => {
      const bio = form.value.bio

      const bioClean = DOMPurify.sanitize(bio, { FORBID_ATTR: ['style', 'font'], FORBID_TAGS: ['style', 'div', 'font'] })

      form.value.bio = bioClean

      try {
        if (img.value.length > 0) {
          const imgUrl = await uploadImg(img.value[0], 'authors')
          form.value.img_url = imgUrl
        }
        if (isUpdated.value) {
          await update(table, form.value)
          notifySuccess('Autor atualizado com sucesso!')
        } else {
          await post(table, form.value)
          notifySuccess('Autor salvo com sucesso!')
        }
        router.push({ name: 'author' })
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleGetAuthor = async (id) => {
      try {
        $q.loading.show()
        author = await getById(table, route.params.id)
        form.value = author
        $q.loading.hide()
      } catch (error) {
        notifyError(error.message)
      }
    }

    return { form, img, handleSubmit, isUpdated }
  }
})
</script>
